import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: 'Quicksand-Light';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url('./assets/fonts/Quicksand-Light.ttf') format('ttf');
      }
      /* latin */
      @font-face {
        font-family: 'Quicksand-Regular';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('./assets/fonts/Quicksand-Regular.ttf') format('ttf');
      }
      /* latin */
      @font-face {
        font-family: 'Quicksand-Medium';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('./assets/fonts/Quicksand-Medium.ttf') format('ttf');
      }
      /* latin */
      @font-face {
        font-family: 'Quicksand-SemiBold';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url('./assets/fonts/Quicksand-SemiBold.ttf') format('ttf');
      }
      /* latin */
      @font-face {
        font-family: 'Quicksand-Bold';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('./assets/fonts/Quicksand-Bold.ttf') format('ttf');
      }
      `}
  />
);

export default Fonts;

export const fontConfig = Object.freeze({
  // primary
  p300: "Quicksand-Light",
  p400: "Quicksand-Regular",
  p500: "Quicksand-Medium",
  p600: "Quicksand-SemiBold",
  p700: "Quicksand-Bold",
  // sizes
  size: {
    xxh1: 45,
    xh1: 35,
    h1: 27,
    h2: 23,
    primary: 20,
    lg: 17,
    md: 14,
    sm: 12,
  },
});
