import { useEffect, useState } from "react";
import Parse from "parse";
import {
  Box,
  HStack,
  Icon,
  Text,
  useBreakpointValue,
  Link,
  Flex,
  Spacer,
  VStack,
  Button,
} from "@chakra-ui/react";
import { useNetwork, useSwitchNetwork } from "wagmi";
import SlidingPane from "react-sliding-pane";
import Lottie from "lottie-react";
import {
  FaXmark,
  FaTimeline,
  FaUsers,
  FaPaperPlane,
  FaPlaneDeparture,
  FaDownload,
  FaPlaneArrival,
} from "react-icons/fa6";
// import Web3 from "web3";
import AppText from "./AppText";

// const web3 = new Web3(Web3.givenProvider);

export default function SlidingPaneClaim({
  slidingPaneClaimState,
  setSlidingPaneClaimState,
  slidingPaneTitle,
  claimArray,
  trackCode,
}) {
  const sliderWidth = useBreakpointValue(
    {
      base: "100vw",
      md: "50vw",
      lg: "40vw",
      xl: "30vw",
    },
    {
      // Breakpoint to use when mediaqueries cannot be used, such as in server-side rendering
      // (Defaults to 'base')
      fallback: "md",
    }
  );

  return (
    <SlidingPane
      className="slider-claim"
      overlayClassName="slider-claim-overlay"
      isOpen={slidingPaneClaimState}
      title={
        <HStack spacing="4">
          <Icon
            // className="button-animation"
            minHeight="36px"
            minWidth="36px"
            as={FaUsers}
          />
          <AppText variant="h2">{slidingPaneTitle}</AppText>
        </HStack>
      }
      width={sliderWidth}
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        setSlidingPaneClaimState(false);
      }}
      closeIcon={
        <Icon
          className="button-animation"
          minHeight="30px"
          minWidth="30px"
          as={FaXmark}
        />
      }
    >
      <Box p="6">
        {claimArray && claimArray.length <= 0 && (
          <AppText variant="lgb">No users to report</AppText>
        )}
        {claimArray &&
          claimArray.length > 0 &&
          claimArray.map((x, i) => (
            <Box key={x.id + i} pb="2">
              <AppText
                variant={Boolean(x.code !== trackCode) ? "lg" : "lgb"}
              >{`${i + 1}) username: ${x.obj.attributes.username}${
                Boolean(x.obj.attributes.email || x.obj.attributes.emailAuth)
                  ? " | email: " +
                    (x.obj.attributes.email ??
                      x.obj.attributes.emailAuth ??
                      "no email provided")
                  : ""
              }${
                Boolean(x.code !== trackCode) ? " | code: " + x.code : ""
              }`}</AppText>
            </Box>
          ))}
      </Box>
    </SlidingPane>
  );
}
