// import { map, filter } from 'lodash';
import configLocal from "../../config/configLocal";
import { createSlice } from "@reduxjs/toolkit";
// Parse
// import Parse from "parse/react-native";
// dispatch
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  autoTranslate: false,
  appIsReady: false,
  isLoading: false,
  error: null,
  displayLanguage: configLocal.displayLanguage,
  fitToMapMarkers: false,
  showTab: true,
  config: null,
  fetchBookings: () => {},
  fetchBroadcasts: () => {},
  fetchListings: () => {},
  fetchLockedReviews: () => {},
  fetchWallet: () => {},
  fetchUserSessions: () => {},
  fetchUserMessages: () => {},
  focusMarker: undefined,
  handleShowUnderlay: () => {},
  showModalUnderlay: false,
  listingsSearchObject: configLocal.listingsSearchObject,
  listingsResults: [],
  listingsFoundTotal: 0,
  // notificationSettings: configLocal.notificationSettings,
  unfilteredListingsResults: [],
  unfilteredListingsFoundTotal: 0,
  rateDataObject: {},
  airdropsRewardsObject: undefined,
  selectedChain: configLocal.selectedChain,
};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setAppIsReadySuccess(state, action) {
      state.isLoading = false;
      state.appIsReady = action.payload;
    },

    setShowTabSuccess(state, action) {
      state.isLoading = false;
      state.showTab = action.payload;
    },

    setAutoTranslateSuccess(state, action) {
      state.isLoading = false;
      state.autoTranslate = action.payload;
    },

    setDisplayLanguageSuccess(state, action) {
      state.isLoading = false;
      state.displayLanguage = action.payload;
    },

    setFitToMapMarkersSuccess(state, action) {
      state.isLoading = false;
      state.fitToMapMarkers = action.payload;
    },

    setConfigSuccess(state, action) {
      state.isLoading = false;
      state.config = action.payload;
      state.selectedChain = action.payload.evmChains.find(
        (x) => x.chainInt === "56"
      ); // default to bsc mainnet chain
    },

    setFetchBookingsSuccess(state, action) {
      state.isLoading = false;
      state.fetchBookings = action.payload;
    },

    setFetchUserMessagesSuccess(state, action) {
      state.isLoading = false;
      state.fetchUserMessages = action.payload;
    },

    setFetchListingsSuccess(state, action) {
      state.isLoading = false;
      state.fetchListings = action.payload;
    },

    setFetchBroadcastsSuccess(state, action) {
      state.isLoading = false;
      state.fetchBroadcasts = action.payload;
    },

    setFetchLockedReviewsSuccess(state, action) {
      state.isLoading = false;
      state.fetchLockedReviews = action.payload;
    },

    setFetchWalletSuccess(state, action) {
      state.isLoading = false;
      state.fetchWallet = action.payload;
    },

    setFetchUserSessionsSuccess(state, action) {
      state.isLoading = false;
      state.fetchUserSessions = action.payload;
    },

    setFocusMarkerSuccess(state, action) {
      state.isLoading = false;
      state.focusMarker = action.payload;
    },

    setHandleShowUnderlaySuccess(state, action) {
      state.isLoading = false;
      state.handleShowUnderlay = action.payload;
    },

    setShowModalUnderlaySuccess(state, action) {
      state.isLoading = false;
      state.showModalUnderlay = action.payload;
    },

    setListingsSearchObjectSuccess(state, action) {
      state.isLoading = false;
      state.listingsSearchObject = action.payload;
    },

    setListingsFoundTotalSuccess(state, action) {
      state.isLoading = false;
      state.listingsFoundTotal = action.payload;
    },

    setUnfilteredListingsFoundTotalSuccess(state, action) {
      state.isLoading = false;
      state.unfilteredListingsFoundTotal = action.payload;
    },

    setListingsResultsSuccess(state, action) {
      state.isLoading = false;
      state.listingsResults = action.payload;
    },

    setUnfilteredListingsResultsSuccess(state, action) {
      state.isLoading = false;
      state.unfilteredListingsResults = action.payload;
    },

    setRateDataObjectSuccess(state, action) {
      state.isLoading = false;
      state.rateDataObject = action.payload;
    },

    setAirdropsRewardsObjectSuccess(state, action) {
      state.isLoading = false;
      state.airdropsRewardsObject = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, stopLoading } = slice.actions;

// ----------------------------------------------------------------------

export function getAppSettings() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const query = new Parse.Query('IcoSettings');
      // const response = await query.first();
      // console.log('response from getIcoSettings action', response.attributes);
      // dispatch(slice.actions.getIcoSettingsSuccess(response.attributes));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setShowTab(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const query = new Parse.Query('IcoSettings');
      // const response = await query.first();
      // console.log('response from getIcoSettings action', response.attributes);
      dispatch(slice.actions.setShowTabSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setAutoTranslateRedux(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const query = new Parse.Query('IcoSettings');
      // const response = await query.first();
      // console.log('response from getIcoSettings action', response.attributes);
      dispatch(slice.actions.setAutoTranslateSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setDisplayLanguageRedux(displayLanguage) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const query = new Parse.Query('IcoSettings');
      // const response = await query.first();
      // console.log('response from getIcoSettings action', response.attributes);
      dispatch(slice.actions.setDisplayLanguageSuccess(displayLanguage));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setFitToMapMarkersRedux(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const query = new Parse.Query('IcoSettings');
      // const response = await query.first();
      // console.log('response from getIcoSettings action', response.attributes);
      dispatch(slice.actions.setFitToMapMarkersSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setAppConfigRedux(response) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setConfigSuccess(response.attributes));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setAppIsReadyRedux(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setAppIsReadySuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setFetchBookingsRedux(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setFetchBookingsSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setFetchUserMessagesRedux(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setFetchUserMessagesSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setFetchListingsRedux(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setFetchListingsSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setFetchBroadcastsRedux(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setFetchBroadcastsSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setFetchLockedReviewsRedux(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setFetchLockedReviewsSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setFetchWalletRedux(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setFetchWalletSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setFetchUserSessionsRedux(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setFetchUserSessionsSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setFocusMarkerRedux(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setFocusMarkerSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setHandleShowUnderlayRedux(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setHandleShowUnderlaySuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setShowModalUnderlayRedux(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setShowModalUnderlaySuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setListingsSearchObjectRedux(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setListingsSearchObjectSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setListingsFoundTotalRedux(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setListingsFoundTotalSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setListingsResultsRedux(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setListingsResultsSuccess(value.filtered));
      dispatch(
        slice.actions.setUnfilteredListingsResultsSuccess(value.unfiltered)
      );
      dispatch(
        slice.actions.setListingsFoundTotalSuccess(value.listingsFoundTotal)
      );
      dispatch(
        slice.actions.setUnfilteredListingsFoundTotalSuccess(
          value.unfiltered.length
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setRateDataObjectRedux(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setRateDataObjectSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setAirdropsRewardsObjectRedux(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setAirdropsRewardsObjectSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
