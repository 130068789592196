export default Object.freeze({
  one: 1,
  xs: 3,
  sm: 5,
  ms: 10,
  md: 15,
  primary: 20,
  lg: 25,
  xl: 30,
  xxl: 40,
  xxxl: 50,
  xxxxl: 65,

  bottomBarSafeIos: 35,
  bottomBar40: 40,
  bottomBar50: 50,
  bottomBar60: 60,
  bottomBar70: 70,
  bottomBar80: 80,
  bottomBar90: 90,
  bottomBar100: 100,
  bottomBar140: 140,
  bottomBar180: 180,

  topBar30: 30,
  topBar40: 40,
  topBar50: 50,
  topBar60: 60,
  topBar70: 70,
  topBar80: 80,
  topBar90: 90,
  topBar100: 100,
  topBar110: 110,
  topBar120: 120,
  topBar130: 130,
  topBar140: 140,
  topBar150: 150,
  topBarWithSubHeader: 105.667,

  mapViewMarker: 60,
  refreshingHeight: 50,
});
