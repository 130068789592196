import { useEffect } from "react";
import PageLayout from "./components/PageLayout";
import AppAuth from "./components/AppAuth";
import AppMain from "./components/AppMain";
import { useYouwho } from "./package/react-youwho";

const App = () => {
  const { user, refetchUserData } = useYouwho();

  useEffect(() => {
    refetchUserData();
  }, []);

  return <PageLayout>{user ? <AppMain /> : <AppAuth />}</PageLayout>;
};

export default App;
