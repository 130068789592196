import { Box, Flex, Text, Button } from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import { useYouwho } from "../package/react-youwho";
import configLocal from "../config/configLocal";

export default function PageLayout({ children }) {
  const { user, logout } = useYouwho();
  return (
    <Box
      // className="p-sm-3"
      minH="600px"
      minW="300px"
      h="100vh"
      w="100vw"
      background="radial-gradient(circle at 20vw 100vh, rgba(255, 94, 14, 0.1) 0%, rgba(160, 160, 160, 0.3) 30%, rgba(0, 201, 153, 0.3) 100%)"
    >
      <Flex
        flexDirection={["column", , "row"]}
        flex="1"
        p="4"
        justifyContent={["space-between"]}
      >
        <Flex flexDirection={["column", , , "row"]}>
          <Flex>
            <a
              href={configLocal.url.website}
              target="_blank"
              className="button-animation"
            >
              <ReactSVG
                // wrapper="svg"

                className="youwho-logo"
                style={{ width: "260px" }}
                src="./assets/svg/youwho-logos_text-logo-new-trans.svg"
              />
            </a>
          </Flex>

          <Flex fontSize="32px" verticalAlign="top">
            <Box display={["none", , , "block"]} mx="3">
              {"|"}
            </Box>
            <Text className="bridge-heading">
              <Text as="b">Referral Code</Text> Tracker
            </Text>
          </Flex>
        </Flex>
        {user && (
          <Flex
            display={["none", , "flex"]}
            justifyContent={["flex-start", , "flex-end"]}
            h="40px"
          >
            <Button onClick={logout} colorScheme="blackAlpha">
              Logout {user?.attributes?.username}
            </Button>
          </Flex>
        )}
      </Flex>

      {children}

      {user && (
        <Box
          display={["block", , "none"]}
          pos="absolute"
          right="0"
          bottom="0"
          p="4"
        >
          <Button onClick={logout} colorScheme="blackAlpha">
            Logout {user?.attributes?.username}
          </Button>
        </Box>
      )}
    </Box>
  );
}
