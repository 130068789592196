import {
  Flex,
  Center,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  VStack,
} from "@chakra-ui/react";
import { Formik, Field } from "formik";
import { useYouwho } from "../package/react-youwho";
import AppText from "./AppText";

export default function AppAuth({}) {
  const { login, isLoading } = useYouwho();
  return (
    <Flex
      className="button-shadow"
      bg="white"
      // alignItems="center"
      justifyContent="center"
      minW={["100vw", "300px"]}
      maxW={["100vw"]}
      minH="300px"
      overflow="hidden"
      borderRadius={["0", "30px"]}
      px={["4", "7"]}
      py={["7", "7"]}
      top={["53%", , , "50%"]}
      left={["0", "50%"]}
      transform={["translate(0, -50%)", "translate(-50%, -50%)"]}
      pos="absolute"
      flex="1"
    >
      <Center>
        <Formik
          initialValues={{
            email: "",
            password: "",
            // rememberMe: false,
          }}
          onSubmit={(values) => {
            login(values.email, values.password);
          }}
        >
          {({ handleSubmit, errors, touched, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl>
                  <FormLabel htmlFor="email">
                    <AppText variant="lgb">Email/Username</AppText>
                  </FormLabel>
                  <Field
                    as={Input}
                    id="email"
                    name="email"
                    placeholder="email or username"
                    // type="email"
                    variant="filled"
                    focusBorderColor="green.400"
                  />
                </FormControl>
                <FormControl isInvalid={!!errors.password && touched.password}>
                  <FormLabel htmlFor="password">
                    <AppText variant="lgb">Password</AppText>
                  </FormLabel>
                  <Field
                    as={Input}
                    id="password"
                    name="password"
                    placeholder="password"
                    type="password"
                    variant="filled"
                    focusBorderColor="green.400"
                    validate={(value) => {
                      let error;

                      if (value.length < 6) {
                        // error =
                        //   "Password must contain at least 6 characters";
                      }

                      return error;
                    }}
                  />
                  <FormErrorMessage>{errors.password}</FormErrorMessage>
                </FormControl>
                {/* <Field
                  as={Checkbox}
                  id="rememberMe"
                  name="rememberMe"
                  colorScheme="green"
                >
                  Remember me?
                </Field> */}
                <Button
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  type="submit"
                  colorScheme="green"
                  sx={{ paddingX: "40px" }}
                  // width="full"
                >
                  Login
                </Button>
              </VStack>
            </form>
          )}
        </Formik>
      </Center>
    </Flex>
  );
}
