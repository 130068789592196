import React, { useCallback, useRef, useEffect, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast as useToastChakra,
  Button,
  useDisclosure,
  ButtonGroup,
  Flex,
  Box,
  HStack,
  Center,
  CloseButton,
  Stack,
} from "@chakra-ui/react";
import { FiCheck, FiCheckCircle, FiXCircle, FiInfo } from "react-icons/fi";

import colors from "../config/colors";
import padding from "../config/padding";
import AppText from "../components/AppText";
import { getPx } from "../utils/helpers";
import borders from "../config/borders";

const variants = {
  success: {
    title: "Success",
    iconName: "check-circle",
    iconAlert: <FiCheckCircle color={colors.primary} size={40} />,
    iconComp: <FiCheckCircle color={colors.primary} size={50} />,
    iconColor: colors.primary,
    colorScheme: "customerBrand",
  },
  error: {
    title: "Error",
    iconName: "no-entry",
    iconAlert: <FiXCircle color={colors.danger} size={40} />,
    iconComp: <FiXCircle color={colors.danger} size={50} />,
    iconColor: colors.danger,
    colorScheme: "errorBrand",
  },
  info: {
    title: "Alert",
    iconName: "info",
    iconAlert: <FiInfo color={colors.info} size={40} />,
    iconComp: <FiInfo color={colors.info} size={50} />,
    iconColor: colors.info,
    colorScheme: "infoBrand",
  },
};

export const useToast = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToastChakra();
  const cancelRef = useRef();
  const [appToastParams, setAppToastParams] = useState();

  const appAlert = useCallback((params) => {
    const { position, autoHide, onHide, props, visibilityTime = 6000 } = params;
    const {
      title,
      subtitle,
      hideClose = false,
      darkToast = false,
      noTranslateSubtitle,
      noTranslateTitle,
      contentColor = !darkToast ? colors.black : colors.white,
      iconName,
      iconSize = 40,
      onClose: onCloseToast = () => {},
      variant = "info",
      fontSize = 25,
      bottom = 180,
      hideLeft,
      iconLeft,
      iconMiddle,
      iconRight,
      rightButtonVariant = "primary",
      titleRight,
      pressLeft,
      pressMiddle,
      pressRight = () => {},
    } = props;

    console.log("run appAlert");

    toast({
      // isClosable: !hideClose,
      position,
      duration: autoHide === false ? null : visibilityTime,
      render: () => (
        <Box
          boxShadow="lg"
          sx={{
            bg: colors.white,
            padding: getPx(padding.md),
            borderRadius: borders.borderRadius12,
            position: "relative",
          }}
        >
          {!hideClose && (
            <CloseButton
              onClick={() => {
                onCloseToast();
                hide();
              }}
              sx={{
                position: "absolute",
                right: getPx(padding.ms),
                top: getPx(padding.ms),
                textAlign: "right",
              }}
            />
          )}
          <HStack>
            <Flex
              sx={{
                justifyContent: "center",
                paddingRight: getPx(padding.ms),
                paddingLeft: getPx(padding.sm),
              }}
            >
              {variants[variant].iconAlert}
            </Flex>
            <Stack>
              <AppText variant="lgb">{title}</AppText>
              <AppText variant="md">{subtitle}</AppText>
            </Stack>
          </HStack>
        </Box>
      ),
      // title: title,
      // description: subtitle,
      // status: variant,
      // onClose: () => {
      //   onCloseToast();
      //   hide();
      // },
      // colorScheme: variants[variant].colorScheme,
      // size: "lg",
      containerStyle: {
        pt: position === "top" ? getPx(padding.topBar80) : undefined,
        pb: position === "bottom" ? getPx(padding.bottomBar80) : undefined,
      },
      // containerStyle: { backgroundColor: "purple" },
    });
  }, []);

  const show = (params) => {
    const { type } = params;
    if (type === "appAlert") {
      appAlert(params);
    } else if (type === "appToast") {
      setAppToastParams(params);
      onOpen();
    }
  };

  const hide = () => {
    onClose();
    setAppToastParams();
    toast.closeAll();
  };

  const AppToast = ({ Component }) => {
    // const { isOpen, onOpen, onClose } = useDisclosure();
    // const cancelRef = React.useRef();
    if (appToastParams) {
      const { position, type, autoHide, onHide, props, visibilityTime } =
        appToastParams;
      const {
        title,
        subtitle,
        hideClose = false,
        darkToast = false,
        noTranslateSubtitle,
        noTranslateTitle,
        contentColor = !darkToast ? colors.black : colors.white,
        iconName,
        iconSize = 40,
        onClose: onCloseToast = () => {},
        variant = "info",
        fontSize = 25,
        bottom = 180,
        hideLeft,
        iconLeft,
        iconMiddle,
        iconRight,
        rightButtonVariant = "solid",
        rightButtonColor = "whatsapp",
        titleRight,
        pressLeft,
        pressMiddle,
        pressRight = () => {},
      } = props;
      console.log("run AppToast");

      return (
        <>
          <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelRef}
            onClose={() => {
              onCloseToast();
              hide();
            }}
            isOpen={isOpen}
            isCentered
          >
            <AlertDialogOverlay />

            <AlertDialogContent sx={{ borderRadius: borders.borderRadius12 }}>
              <HStack spacing={0}>
                <Box
                  sx={{
                    width: "15%",

                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Flex sx={{ justifyContent: "flex-end" }}>
                    {variants[variant].iconComp}
                  </Flex>
                </Box>
                <Box sx={{ width: "85%" }}>
                  <AlertDialogHeader>
                    <AppText variant="h3">{title}</AppText>
                  </AlertDialogHeader>
                  <AlertDialogCloseButton />
                  <AlertDialogBody>
                    <AppText variant="lg">{subtitle}</AppText>
                  </AlertDialogBody>
                  <AlertDialogFooter>
                    <ButtonGroup spacing="6">
                      {!hideLeft && (
                        <Button
                          ref={cancelRef}
                          onClick={pressLeft ? () => pressLeft() : () => hide()}
                          variant={"outline"}
                        >
                          {iconLeft ? iconLeft : <FiCheck />}
                        </Button>
                      )}
                      {iconMiddle && pressMiddle && (
                        <Button onClick={pressMiddle} variant={"outline"}>
                          {iconMiddle ?? <FiCheck />}
                        </Button>
                      )}
                      <Button
                        onClick={() => {
                          onCloseToast();
                          hide();
                        }}
                        variant={rightButtonVariant}
                        colorScheme={rightButtonColor}
                      >
                        {/* <AppText sx={{ color: "white" }}>
                          test
                        </AppText> */}
                        <FiCheck />
                      </Button>
                    </ButtonGroup>
                  </AlertDialogFooter>
                </Box>
              </HStack>
            </AlertDialogContent>
          </AlertDialog>
        </>
      );
    }
  };

  return { show, hide, AppToast };
};
