export default Object.freeze({
  daysArray: [
    { day: "Sunday", date: "2021-10-03" },
    { day: "Monday", date: "2021-10-04" },
    { day: "Tuesday", date: "2021-10-05" },
    { day: "Wednesday", date: "2021-10-06" },
    { day: "Thursday", date: "2021-10-07" },
    { day: "Friday", date: "2021-10-08" },
    { day: "Saturday", date: "2021-10-09" },
  ],
  version: "v. 1.1.07",
  displayLanguage: "en",
  displayCurrency: "USD",
  listingsSearchObject: {
    searchText: "who do you need?",
    id: "",
    string: "",
    category: [],
    serviceLocation: "",
    searchLocation: "", // near or country
    searchWithinKm: "20", // if near then use this as a geo
    country: [], // if country then
    limit: 51,
    maxResults: 510,
    filters: {
      rateLower: "", // range
      rateUpper: "", // range
      certification: false,
      verifiedUsers: false,
      reviews: "", // "sort most popular first" "sort least popular first" ascending descending
      ratingLower: 0, // range
      ratingUpper: 5, // range
      age: "sort most active first", // "sort least active first" ascending descending
    },
  },
  selectedChain: {
    chainName: "bsc",
    chainId: "0x38",
    chainType: "main",
    chainInt: "56",
    chainTitle: "Binance Smart Chain",
    nativeToken: "bnb",
    nativeTokenName: "Binance",
    isSupported: true,
  },
  selectedLocation: {
    latitude: 40.7127744,
    longitude: -74.0082477,
    delta: undefined,
    name: undefined,
    address: undefined,
    country: {
      long_name: "United States of America",
      short_name: "US",
    },
  },
  notificationSettings: {
    bookings: {
      id: "bookings",
      name: "Bookings",
      importance: "HIGH",
      sound: "youwhoalert",
      enabled: true,
    },
    general: {
      id: "general",
      name: "General",
      importance: "HIGH",
      sound: "youwhoalert",
      enabled: true,
    },
    messages: {
      id: "messages",
      name: "Messages",
      importance: "HIGH",
      sound: "youwhoalert",
      enabled: true,
    },
    rewards: {
      id: "rewards",
      name: "Rewards",
      importance: "HIGH",
      sound: "youwhoalert",
      enabled: true,
    },
    transfers: {
      id: "transfers",
      name: "Transfers",
      importance: "HIGH",
      sound: "youwhoalert",
      enabled: true,
    },
  },
  url: {
    gmapsQuery: "https://maps.google.com/maps?q=",
    discord: "https://discord.youwho.io",
    github: "https://git.youwho.io",
    website: "https://youwho.io",
    howYouwhoWorks: "https://why.youwho.io/#how",
    buyYou: "https://buy.youwho.io",
    moralisIpfsGateway: "https://gateway.moralisipfs.com/ipfs/",
    backupIpfsGateway: "https://ipfs.io/ipfs/",
    accountDeletion: "https://why.youwho.io/account-deletion/",
  },
  categories: [
    "cleaner",
    "nurse",
    "healthcare assistant",
    "landscaper",
    "gardener",
    "vet",
    "plumber",
    "pool cleaner",
    "vehicle road side assistance",
    "mechanic",
    "tutor",
    "teacher",
    "electrician",
    "dog walker",
    "personal trainer",
    "bookkeeper",
    "accountant",
    "hospitality staff",
    "dog trainer",
    "manicurist / pedicurist",
    "mobile phone repairer",
    "personal chef",
    "chef",
    "painter",
    "pet sitter",
    "baby sitter",
    "hair dresser",
    "movers",
    "pest controller",
    "music teacher",
    "interior designer",
    "legal consultant",
    "lawyer",
    "flooring / carpet installer",
    "agricultural hand",
    "general labourer",
    "florist",
    "physiotherapist",
    "chiropractor",
    "tour guide",
    "psychologist",
    "private investigator",
    "mid wife",
    "wet nurse",
    "translator",
    "dj",
    "clown",
    "it specialist",
    "carpenter",
    "programmer",
    "web developer",
    "engineer",
    "maid",
    "other",
  ],
  customMapStyle: [
    {
      featureType: "administrative",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          lightness: "0",
        },
      ],
    },
    {
      featureType: "poi.medical",
      elementType: "geometry.fill",
      stylers: [
        {
          lightness: "-5",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#a7ce95",
        },
        {
          lightness: "45",
        },
      ],
    },
    {
      featureType: "poi.school",
      elementType: "geometry",
      stylers: [
        {
          color: "#be9b7b",
        },
        {
          lightness: "70",
        },
      ],
    },
    {
      featureType: "poi.sports_complex",
      elementType: "geometry",
      stylers: [
        {
          color: "#5d4b46",
        },
        {
          lightness: "60",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          saturation: "23",
        },
        {
          lightness: "10",
        },
        {
          gamma: "0.8",
        },
        {
          hue: "#b000ff",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#a2daf2",
        },
      ],
    },
  ],
  languageList: [
    {
      languageNameEnglish: "Afrikaans",
      languageCode: "af",
      languageNameNative: "Afrikaans",
    },
    {
      languageNameEnglish: "Albanian",
      languageCode: "sq",
      languageNameNative: "shqip / gjuha shqipe",
    },
    {
      languageNameEnglish: "Amharic",
      languageCode: "am",
      languageNameNative: "ኣማርኛ (amarəñña)",
    },
    {
      languageNameEnglish: "Arabic",
      languageCode: "ar",
      languageNameNative: "(al arabiya) العربية",
    },
    {
      languageNameEnglish: "Armenian",
      languageCode: "hy",
      languageNameNative: "Հայերէն (Hayeren) / Հայերեն լեզու (Hayeren lezou)",
    },
    {
      languageNameEnglish: "Assamese",
      languageCode: "as",
      languageNameNative: "অসমীয়া (asamīẏa)",
    },
    {
      languageNameEnglish: "Aymara",
      languageCode: "ay",
      languageNameNative: "aymar aru",
    },
    {
      languageNameEnglish: "Azerbaijani",
      languageCode: "az",
      languageNameNative:
        "Azərbaycan dili / aзәp6ajҹaн дили / آذربايجانجا ديلي",
    },
    {
      languageNameEnglish: "Bambara",
      languageCode: "bm",
      languageNameNative: "Bamanankan",
    },
    {
      languageNameEnglish: "Basque",
      languageCode: "eu",
      languageNameNative: "euskara",
    },
    {
      languageNameEnglish: "Belarusian",
      languageCode: "be",
      languageNameNative: "Бeлapycкaя мoвa (Bielaruskaja mova)",
    },
    {
      languageNameEnglish: "Bengali",
      languageCode: "bn",
      languageNameNative: "বাংলা (baɛṅlā)",
    },
    {
      languageNameEnglish: "Bhojpuri",
      languageCode: "bho",
      languageNameNative: "भोजपुरी (bʰojpurī)",
    },
    {
      languageNameEnglish: "Bosnian",
      languageCode: "bs",
      languageNameNative: "bosanski / 6ocaнcки / بۉسانسقى",
    },
    {
      languageNameEnglish: "Bulgarian",
      languageCode: "bg",
      languageNameNative:
        "6ълrapcки (bãlgarski) 6ълrapcки eзик (bãlgarski ezik)",
    },
    {
      languageNameEnglish: "Catalan",
      languageCode: "ca",
      languageNameNative: "català",
    },
    {
      languageNameEnglish: "Cebuano",
      languageCode: "ceb",
      languageNameNative: "Sinugboanon / Sugboanon",
    },
    {
      languageNameEnglish: "Chichewa",
      languageCode: "ny",
      languageNameNative: "Chicheŵa",
    },
    {
      languageNameEnglish: "Chinese (Simplified)",
      languageCode: "zh-CN",
      languageNameNative: "中国人",
    },
    {
      languageNameEnglish: "Chinese (Traditional)",
      languageCode: "zh-TW",
      languageNameNative: "中國人",
    },
    {
      languageNameEnglish: "Corsican",
      languageCode: "co",
      languageNameNative: "corsu",
    },
    {
      languageNameEnglish: "Croatian",
      languageCode: "hr",
      languageNameNative: "Hrvatski",
    },
    {
      languageNameEnglish: "Czech",
      languageCode: "cs",
      languageNameNative: "čeština / český jazyk",
    },
    {
      languageNameEnglish: "Danish",
      languageCode: "da",
      languageNameNative: "dansk",
    },
    {
      languageNameEnglish: "Dhivehi",
      languageCode: "dv",
      languageNameNative: "ދިވެހި",
    },
    {
      languageNameEnglish: "Dogri",
      languageCode: "doi",
      languageNameNative: "डोगरी",
    },
    {
      languageNameEnglish: "Dutch",
      languageCode: "nl",
      languageNameNative: "Nederlands",
    },
    {
      languageNameEnglish: "English",
      languageCode: "en",
      languageNameNative: "English",
    },
    {
      languageNameEnglish: "Esperanto",
      languageCode: "eo",
      languageNameNative: "Esperanto",
    },
    {
      languageNameEnglish: "Estonian",
      languageCode: "et",
      languageNameNative: "eesti keel",
    },
    {
      languageNameEnglish: "Ewe",
      languageCode: "ee",
      languageNameNative: "Eʋegbe",
    },
    {
      languageNameEnglish: "Filipino",
      languageCode: "tl",
      languageNameNative: "Filipino",
    },
    {
      languageNameEnglish: "Finnish",
      languageCode: "fi",
      languageNameNative: "suomi / suomen kieli",
    },
    {
      languageNameEnglish: "French",
      languageCode: "fr",
      languageNameNative: "français",
    },
    {
      languageNameEnglish: "Frisian",
      languageCode: "fy",
      languageNameNative: "Frysk",
    },
    {
      languageNameEnglish: "Galician",
      languageCode: "gl",
      languageNameNative: "Galego",
    },
    {
      languageNameEnglish: "Georgian",
      languageCode: "ka",
      languageNameNative: "ქართული (k'art'uli) ქართული ენა (k'art'uli ena)",
    },
    {
      languageNameEnglish: "German",
      languageCode: "de",
      languageNameNative: "Deutsch",
    },
    {
      languageNameEnglish: "Greek",
      languageCode: "el",
      languageNameNative: "ελληνικά (ellēniká)",
    },
    {
      languageNameEnglish: "Guarani",
      languageCode: "gn",
      languageNameNative: "Avañe'ẽ",
    },
    {
      languageNameEnglish: "Gujarati",
      languageCode: "gu",
      languageNameNative: "ગુજરાતી (gujarātī)",
    },
    {
      languageNameEnglish: "Haitian Creole",
      languageCode: "ht",
      languageNameNative: "Kreyòl ayisyen",
    },
    {
      languageNameEnglish: "Hausa",
      languageCode: "ha",
      languageNameNative: "(ḥawsa) حَوْسَ",
    },
    {
      languageNameEnglish: "Hawaiian",
      languageCode: "haw",
      languageNameNative: "'ōlelo Hawai'i",
    },
    {
      languageNameEnglish: "Hebrew",
      languageCode: "iw",
      languageNameNative: "(ivrit) עברית / עִבְרִית",
    },
    {
      languageNameEnglish: "Hebrew",
      languageCode: "he",
      languageNameNative: "(ivrit) עברית / עִבְרִית",
    },
    {
      languageNameEnglish: "Hindi",
      languageCode: "hi",
      languageNameNative: "हिन्दी (hindī)",
    },
    {
      languageNameEnglish: "Hmong",
      languageCode: "hmn",
      languageNameNative: "Hmoob",
    },
    {
      languageNameEnglish: "Hungarian",
      languageCode: "hu",
      languageNameNative: "magyar / magyar nyelv",
    },
    {
      languageNameEnglish: "Icelandic",
      languageCode: "is",
      languageNameNative: "Íslenska",
    },
    {
      languageNameEnglish: "Igbo",
      languageCode: "ig",
      languageNameNative: "igbo",
    },
    {
      languageNameEnglish: "Ilocano",
      languageCode: "ilo",
      languageNameNative: "ilokano",
    },
    {
      languageNameEnglish: "Indonesian",
      languageCode: "id",
      languageNameNative: "Bahasa Indonesia",
    },
    {
      languageNameEnglish: "Irish",
      languageCode: "ga",
      languageNameNative: "Gaeilge",
    },
    {
      languageNameEnglish: "Italian",
      languageCode: "it",
      languageNameNative: "italiano",
    },
    {
      languageNameEnglish: "Japanese",
      languageCode: "ja",
      languageNameNative: "日本語 (nihongo)",
    },
    {
      languageNameEnglish: "Javanese",
      languageCode: "jw",
      languageNameNative: "basa Jawa",
    },
    {
      languageNameEnglish: "Kannada",
      languageCode: "kn",
      languageNameNative: "ಕನ್ನಡ (kannaḍa)",
    },
    {
      languageNameEnglish: "Kazakh",
      languageCode: "kk",
      languageNameNative: "Қaзaқ тілі / Qazaq tili / قازاق ٴتىلى",
    },
    {
      languageNameEnglish: "Khmer",
      languageCode: "km",
      languageNameNative: "ភាសាខ្មែរ (bhāsā khmɛ̄r)",
    },
    {
      languageNameEnglish: "Kinyarwanda",
      languageCode: "rw",
      languageNameNative: "Ikinyarwanda",
    },
    {
      languageNameEnglish: "Konkani",
      languageCode: "gom",
      languageNameNative: "कोंकणी (kōṅkaṇī) ಕೊಂಕಣಿ (koṅkaṇi)",
    },
    {
      languageNameEnglish: "Korean",
      languageCode: "ko",
      languageNameNative: "한국어 [韓國語] (han-guk-eo)",
    },
    {
      languageNameEnglish: "Krio",
      languageCode: "kri",
      languageNameNative: "Krio",
    },
    {
      languageNameEnglish: "Kurdish (Kurmanji)",
      languageCode: "ku",
      languageNameNative: "Kurdî",
    },
    {
      languageNameEnglish: "Kurdish (Sorani)",
      languageCode: "ckb",
      languageNameNative: "کوردی",
    },
    {
      languageNameEnglish: "Kyrgyz",
      languageCode: "ky",
      languageNameNative: "قىرعىز (kyrgyz) قىرعىز تىلى (kyrgyz tili)",
    },
    {
      languageNameEnglish: "Lao",
      languageCode: "lo",
      languageNameNative: "ພາສາລາວ (pháasaa láo)",
    },
    {
      languageNameEnglish: "Latin",
      languageCode: "la",
      languageNameNative: "Lingua Latina",
    },
    {
      languageNameEnglish: "Latvian",
      languageCode: "lv",
      languageNameNative: "latviešu valoda",
    },
    {
      languageNameEnglish: "Lingala",
      languageCode: "ln",
      languageNameNative: "lingála",
    },
    {
      languageNameEnglish: "Lithuanian",
      languageCode: "lt",
      languageNameNative: "lietuvių kalba",
    },
    {
      languageNameEnglish: "Luganda",
      languageCode: "lg",
      languageNameNative: "Oluganda",
    },
    {
      languageNameEnglish: "Luxembourgish",
      languageCode: "lb",
      languageNameNative: "Lëtzebuergesch",
    },
    {
      languageNameEnglish: "Macedonian",
      languageCode: "mk",
      languageNameNative:
        "мaкeдoнcки (Makedonski) мaкeдoнcки jaзик (makedonski jazik)",
    },
    {
      languageNameEnglish: "Maithili",
      languageCode: "mai",
      languageNameNative: "मैथिली (mɛtʰilī)",
    },
    {
      languageNameEnglish: "Malagasy",
      languageCode: "mg",
      languageNameNative: "Fiteny Malagasy",
    },
    {
      languageNameEnglish: "Malay",
      languageCode: "ms",
      languageNameNative: "Bahasa melayu",
    },
    {
      languageNameEnglish: "Malayalam",
      languageCode: "ml",
      languageNameNative: "മലയാളം (malayāḷam)",
    },
    {
      languageNameEnglish: "Maltese",
      languageCode: "mt",
      languageNameNative: "Malti",
    },
    {
      languageNameEnglish: "Maori",
      languageCode: "mi",
      languageNameNative: "Maori",
    },
    {
      languageNameEnglish: "Marathi",
      languageCode: "mr",
      languageNameNative: "मराठी (marāṭhī)",
    },
    {
      languageNameEnglish: "Meiteilon (Manipuri)",
      languageCode: "mni-Mtei",
      languageNameNative: "Meiteilon",
    },
    {
      languageNameEnglish: "Mizo",
      languageCode: "lus",
      languageNameNative: "Mizo tawng",
    },
    {
      languageNameEnglish: "Mongolian",
      languageCode: "mn",
      languageNameNative: "мoнroл (mongol) мoнroл xэл (mongol hêl)",
    },
    {
      languageNameEnglish: "Myanmar (Burmese)",
      languageCode: "my",
      languageNameNative: "မြန်မာ",
    },
    {
      languageNameEnglish: "Nepali",
      languageCode: "ne",
      languageNameNative: "नेपाली (nēpālī)",
    },
    {
      languageNameEnglish: "Norwegian",
      languageCode: "no",
      languageNameNative: "Norsk",
    },
    {
      languageNameEnglish: "Odia (Oriya)",
      languageCode: "or",
      languageNameNative: "ଓଡିଆ",
    },
    {
      languageNameEnglish: "Oromo",
      languageCode: "om",
      languageNameNative: "Afaan Oromo",
    },
    {
      languageNameEnglish: "Pashto",
      languageCode: "ps",
      languageNameNative: "(paṧto) پښتو",
    },
    {
      languageNameEnglish: "Persian",
      languageCode: "fa",
      languageNameNative: "(fārsī) فارسى",
    },
    {
      languageNameEnglish: "Polish",
      languageCode: "pl",
      languageNameNative: "polski / język polski / polszczyzna",
    },
    {
      languageNameEnglish: "Portuguese",
      languageCode: "pt",
      languageNameNative: "português",
    },
    {
      languageNameEnglish: "Punjabi",
      languageCode: "pa",
      languageNameNative: "ਪੰਜਾਬੀ / ﺏﺎﺠﻨﭘ (panjābi)",
    },
    {
      languageNameEnglish: "Quechua",
      languageCode: "qu",
      languageNameNative: "Qhichwa",
    },
    {
      languageNameEnglish: "Romanian",
      languageCode: "ro",
      languageNameNative: "limba română / român",
    },
    {
      languageNameEnglish: "Russian",
      languageCode: "ru",
      languageNameNative: "pyccкий язык (Russkij jazyk)",
    },
    {
      languageNameEnglish: "Samoan",
      languageCode: "sm",
      languageNameNative: "Gagana Samoa",
    },
    {
      languageNameEnglish: "Sanskrit",
      languageCode: "sa",
      languageNameNative:
        "संस्कृतम् (saṃskṛtam) संस्कृता भाषा (saṃskṛtā bhāṣā)",
    },
    {
      languageNameEnglish: "Scots Gaelic",
      languageCode: "gd",
      languageNameNative: "Gàidhlig na h-Alba",
    },
    {
      languageNameEnglish: "Sepedi",
      languageCode: "nso",
      languageNameNative: "Sepedi",
    },
    {
      languageNameEnglish: "Serbian",
      languageCode: "sr",
      languageNameNative: "cpпcки (srpski) cpпcки jeзик (srpski jezik)",
    },
    {
      languageNameEnglish: "Sesotho",
      languageCode: "st",
      languageNameNative: "Sesotho",
    },
    {
      languageNameEnglish: "Shona",
      languageCode: "sn",
      languageNameNative: "chiShona",
    },
    {
      languageNameEnglish: "Sindhi",
      languageCode: "sd",
      languageNameNative: "(sindhī) سنڌي",
    },
    {
      languageNameEnglish: "Sinhala",
      languageCode: "si",
      languageNameNative: "සිංහල (sĩhala)",
    },
    {
      languageNameEnglish: "Slovak",
      languageCode: "sk",
      languageNameNative: "slovenčina",
    },
    {
      languageNameEnglish: "Slovenian",
      languageCode: "sl",
      languageNameNative: "slovenščina",
    },
    {
      languageNameEnglish: "Somali",
      languageCode: "so",
      languageNameNative: "af Soomaali",
    },
    {
      languageNameEnglish: "Spanish",
      languageCode: "es",
      languageNameNative: "español / castellano",
    },
    {
      languageNameEnglish: "Sundanese",
      languageCode: "su",
      languageNameNative: "Basa Sunda",
    },
    {
      languageNameEnglish: "Swahili",
      languageCode: "sw",
      languageNameNative: "Kiswahili",
    },
    {
      languageNameEnglish: "Swedish",
      languageCode: "sv",
      languageNameNative: "Svenska",
    },
    {
      languageNameEnglish: "Tajik",
      languageCode: "tg",
      languageNameNative: "тoҷики / toçikī / تاجيكي",
    },
    {
      languageNameEnglish: "Tamil",
      languageCode: "ta",
      languageNameNative: "தமிழ் (tamiḻ)",
    },
    {
      languageNameEnglish: "Tatar",
      languageCode: "tt",
      languageNameNative: "тaтapчa / tatar tele / تاتارچا (tatarça)",
    },
    {
      languageNameEnglish: "Telugu",
      languageCode: "te",
      languageNameNative: "తెలుగు (telugu)",
    },
    {
      languageNameEnglish: "Thai",
      languageCode: "th",
      languageNameNative: "ภาษาไทย (paasaa-tai)",
    },
    {
      languageNameEnglish: "Tigrinya",
      languageCode: "ti",
      languageNameNative: "ትግርኛ (təgərəña)",
    },
    {
      languageNameEnglish: "Tsonga",
      languageCode: "ts",
      languageNameNative: "xiTsonga",
    },
    {
      languageNameEnglish: "Turkish",
      languageCode: "tr",
      languageNameNative: "Türkçe",
    },
    {
      languageNameEnglish: "Turkmen",
      languageCode: "tk",
      languageNameNative: "түpкmeнчe (türkmençe) түpкмeн дили (türkmen dili)",
    },
    {
      languageNameEnglish: "Twi",
      languageCode: "ak",
      languageNameNative: "twi",
    },
    {
      languageNameEnglish: "Ukrainian",
      languageCode: "uk",
      languageNameNative: "yкpaїнcькa (Ukrajins'ka)",
    },
    {
      languageNameEnglish: "Urdu",
      languageCode: "ur",
      languageNameNative: "(urdū) اردو",
    },
    {
      languageNameEnglish: "Uyghur",
      languageCode: "ug",
      languageNameNative: "yйғyp /ئۇيغۇر (ujġgur / uyghur)",
    },
    {
      languageNameEnglish: "Uzbek",
      languageCode: "uz",
      languageNameNative: "أۇزبېك ﺗﻴﻠی o'zbek tili ўз6eк тили (o'zbek tili)",
    },
    {
      languageNameEnglish: "Vietnamese",
      languageCode: "vi",
      languageNameNative: "tiếng việt (㗂越)",
    },
    {
      languageNameEnglish: "Welsh",
      languageCode: "cy",
      languageNameNative: "Cymraeg / Y Gymraeg",
    },
    {
      languageNameEnglish: "Xhosa",
      languageCode: "xh",
      languageNameNative: "isiXhosa",
    },
    {
      languageNameEnglish: "Yiddish",
      languageCode: "yi",
      languageNameNative: "(Yidish) ײִדיש",
    },
    {
      languageNameEnglish: "Yoruba",
      languageCode: "yo",
      languageNameNative: "Yorùbá",
    },
    {
      languageNameEnglish: "Zulu",
      languageCode: "zu",
      languageNameNative: "isiZulu",
    },
  ],
  countryCodes: [
    {
      countryName: "Afghanistan",
      countryCode: "AF",
    },
    {
      countryName: "Albania",
      countryCode: "AL",
    },
    {
      countryName: "Algeria",
      countryCode: "DZ",
    },
    {
      countryName: "American Samoa",
      countryCode: "AS",
    },
    {
      countryName: "Andorra",
      countryCode: "AD",
    },
    {
      countryName: "Angola",
      countryCode: "AO",
    },
    {
      countryName: "Anguilla",
      countryCode: "AI",
    },
    {
      countryName: "Antarctica",
      countryCode: "AQ",
    },
    {
      countryName: "Antigua and Barbuda",
      countryCode: "AG",
    },
    {
      countryName: "Argentina",
      countryCode: "AR",
    },
    {
      countryName: "Armenia",
      countryCode: "AM",
    },
    {
      countryName: "Aruba",
      countryCode: "AW",
    },
    {
      countryName: "Australia",
      countryCode: "AU",
    },
    {
      countryName: "Austria",
      countryCode: "AT",
    },
    {
      countryName: "Azerbaijan",
      countryCode: "AZ",
    },
    {
      countryName: "Bahamas",
      countryCode: "BS",
    },
    {
      countryName: "Bahrain",
      countryCode: "BH",
    },
    {
      countryName: "Bangladesh",
      countryCode: "BD",
    },
    {
      countryName: "Barbados",
      countryCode: "BB",
    },
    {
      countryName: "Belarus",
      countryCode: "BY",
    },
    {
      countryName: "Belgium",
      countryCode: "BE",
    },
    {
      countryName: "Belize",
      countryCode: "BZ",
    },
    {
      countryName: "Benin",
      countryCode: "BJ",
    },
    {
      countryName: "Bermuda",
      countryCode: "BM",
    },
    {
      countryName: "Bhutan",
      countryCode: "BT",
    },
    {
      countryName: "Bolivia (Plurinational State of)",
      countryCode: "BO",
    },
    {
      countryName: "Bonaire, Sint Eustatius and Saba",
      countryCode: "BQ",
    },
    {
      countryName: "Bosnia and Herzegovina",
      countryCode: "BA",
    },
    {
      countryName: "Botswana",
      countryCode: "BW",
    },
    {
      countryName: "Bouvet Island",
      countryCode: "BV",
    },
    {
      countryName: "Brazil",
      countryCode: "BR",
    },
    {
      countryName: "British Indian Ocean Territory",
      countryCode: "IO",
    },
    {
      countryName: "Brunei Darussalam",
      countryCode: "BN",
    },
    {
      countryName: "Bulgaria",
      countryCode: "BG",
    },
    {
      countryName: "Burkina Faso",
      countryCode: "BF",
    },
    {
      countryName: "Burundi",
      countryCode: "BI",
    },
    {
      countryName: "Cabo Verde",
      countryCode: "CV",
    },
    {
      countryName: "Cambodia",
      countryCode: "KH",
    },
    {
      countryName: "Cameroon",
      countryCode: "CM",
    },
    {
      countryName: "Canada",
      countryCode: "CA",
    },
    {
      countryName: "Cayman Islands",
      countryCode: "KY",
    },
    {
      countryName: "Central African Republic",
      countryCode: "CF",
    },
    {
      countryName: "Chad",
      countryCode: "TD",
    },
    {
      countryName: "Chile",
      countryCode: "CL",
    },
    {
      countryName: "China",
      countryCode: "CN",
    },
    {
      countryName: "Christmas Island",
      countryCode: "CX",
    },
    {
      countryName: "Cocos (Keeling) Islands",
      countryCode: "CC",
    },
    {
      countryName: "Colombia",
      countryCode: "CO",
    },
    {
      countryName: "Comoros",
      countryCode: "KM",
    },
    {
      countryName: "Congo (the Democratic Republic of the)",
      countryCode: "CD",
    },
    {
      countryName: "Congo",
      countryCode: "CG",
    },
    {
      countryName: "Cook Islands",
      countryCode: "CK",
    },
    {
      countryName: "Costa Rica",
      countryCode: "CR",
    },
    {
      countryName: "Croatia",
      countryCode: "HR",
    },
    {
      countryName: "Cuba",
      countryCode: "CU",
    },
    {
      countryName: "Curaçao",
      countryCode: "CW",
    },
    {
      countryName: "Cyprus",
      countryCode: "CY",
    },
    {
      countryName: "Czechia",
      countryCode: "CZ",
    },
    {
      countryName: "Côte d'Ivoire",
      countryCode: "CI",
    },
    {
      countryName: "Denmark",
      countryCode: "DK",
    },
    {
      countryName: "Djibouti",
      countryCode: "DJ",
    },
    {
      countryName: "Dominica",
      countryCode: "DM",
    },
    {
      countryName: "Dominican Republic",
      countryCode: "DO",
    },
    {
      countryName: "Ecuador",
      countryCode: "EC",
    },
    {
      countryName: "Egypt",
      countryCode: "EG",
    },
    {
      countryName: "El Salvador",
      countryCode: "SV",
    },
    {
      countryName: "Equatorial Guinea",
      countryCode: "GQ",
    },
    {
      countryName: "Eritrea",
      countryCode: "ER",
    },
    {
      countryName: "Estonia",
      countryCode: "EE",
    },
    {
      countryName: "Eswatini",
      countryCode: "SZ",
    },
    {
      countryName: "Ethiopia",
      countryCode: "ET",
    },
    {
      countryName: "Falkland Islands [Malvinas]",
      countryCode: "FK",
    },
    {
      countryName: "Faroe Islands",
      countryCode: "FO",
    },
    {
      countryName: "Fiji",
      countryCode: "FJ",
    },
    {
      countryName: "Finland",
      countryCode: "FI",
    },
    {
      countryName: "France",
      countryCode: "FR",
    },
    {
      countryName: "French Guiana",
      countryCode: "GF",
    },
    {
      countryName: "French Polynesia",
      countryCode: "PF",
    },
    {
      countryName: "French Southern Territories",
      countryCode: "TF",
    },
    {
      countryName: "Gabon",
      countryCode: "GA",
    },
    {
      countryName: "Gambia",
      countryCode: "GM",
    },
    {
      countryName: "Georgia",
      countryCode: "GE",
    },
    {
      countryName: "Germany",
      countryCode: "DE",
    },
    {
      countryName: "Ghana",
      countryCode: "GH",
    },
    {
      countryName: "Gibraltar",
      countryCode: "GI",
    },
    {
      countryName: "Greece",
      countryCode: "GR",
    },
    {
      countryName: "Greenland",
      countryCode: "GL",
    },
    {
      countryName: "Grenada",
      countryCode: "GD",
    },
    {
      countryName: "Guadeloupe",
      countryCode: "GP",
    },
    {
      countryName: "Guam",
      countryCode: "GU",
    },
    {
      countryName: "Guatemala",
      countryCode: "GT",
    },
    {
      countryName: "Guernsey",
      countryCode: "GG",
    },
    {
      countryName: "Guinea",
      countryCode: "GN",
    },
    {
      countryName: "Guinea-Bissau",
      countryCode: "GW",
    },
    {
      countryName: "Guyana",
      countryCode: "GY",
    },
    {
      countryName: "Haiti",
      countryCode: "HT",
    },
    {
      countryName: "Heard Island and McDonald Islands",
      countryCode: "HM",
    },
    {
      countryName: "Holy See",
      countryCode: "VA",
    },
    {
      countryName: "Honduras",
      countryCode: "HN",
    },
    {
      countryName: "Hong Kong",
      countryCode: "HK",
    },
    {
      countryName: "Hungary",
      countryCode: "HU",
    },
    {
      countryName: "Iceland",
      countryCode: "IS",
    },
    {
      countryName: "India",
      countryCode: "IN",
    },
    {
      countryName: "Indonesia",
      countryCode: "ID",
    },
    {
      countryName: "Iran (Islamic Republic of)",
      countryCode: "IR",
    },
    {
      countryName: "Iraq",
      countryCode: "IQ",
    },
    {
      countryName: "Ireland",
      countryCode: "IE",
    },
    {
      countryName: "Isle of Man",
      countryCode: "IM",
    },
    {
      countryName: "Israel",
      countryCode: "IL",
    },
    {
      countryName: "Italy",
      countryCode: "IT",
    },
    {
      countryName: "Jamaica",
      countryCode: "JM",
    },
    {
      countryName: "Japan",
      countryCode: "JP",
    },
    {
      countryName: "Jersey",
      countryCode: "JE",
    },
    {
      countryName: "Jordan",
      countryCode: "JO",
    },
    {
      countryName: "Kazakhstan",
      countryCode: "KZ",
    },
    {
      countryName: "Kenya",
      countryCode: "KE",
    },
    {
      countryName: "Kiribati",
      countryCode: "KI",
    },
    {
      countryName: "Korea (the Democratic People's Republic of)",
      countryCode: "KP",
    },
    {
      countryName: "Korea (the Republic of)",
      countryCode: "KR",
    },
    {
      countryName: "Kuwait",
      countryCode: "KW",
    },
    {
      countryName: "Kyrgyzstan",
      countryCode: "KG",
    },
    {
      countryName: "Lao People's Democratic Republic",
      countryCode: "LA",
    },
    {
      countryName: "Latvia",
      countryCode: "LV",
    },
    {
      countryName: "Lebanon",
      countryCode: "LB",
    },
    {
      countryName: "Lesotho",
      countryCode: "LS",
    },
    {
      countryName: "Liberia",
      countryCode: "LR",
    },
    {
      countryName: "Libya",
      countryCode: "LY",
    },
    {
      countryName: "Liechtenstein",
      countryCode: "LI",
    },
    {
      countryName: "Lithuania",
      countryCode: "LT",
    },
    {
      countryName: "Luxembourg",
      countryCode: "LU",
    },
    {
      countryName: "Macao",
      countryCode: "MO",
    },
    {
      countryName: "Madagascar",
      countryCode: "MG",
    },
    {
      countryName: "Malawi",
      countryCode: "MW",
    },
    {
      countryName: "Malaysia",
      countryCode: "MY",
    },
    {
      countryName: "Maldives",
      countryCode: "MV",
    },
    {
      countryName: "Mali",
      countryCode: "ML",
    },
    {
      countryName: "Malta",
      countryCode: "MT",
    },
    {
      countryName: "Marshall Islands",
      countryCode: "MH",
    },
    {
      countryName: "Martinique",
      countryCode: "MQ",
    },
    {
      countryName: "Mauritania",
      countryCode: "MR",
    },
    {
      countryName: "Mauritius",
      countryCode: "MU",
    },
    {
      countryName: "Mayotte",
      countryCode: "YT",
    },
    {
      countryName: "Mexico",
      countryCode: "MX",
    },
    {
      countryName: "Micronesia (Federated States of)",
      countryCode: "FM",
    },
    {
      countryName: "Moldova (the Republic of)",
      countryCode: "MD",
    },
    {
      countryName: "Monaco",
      countryCode: "MC",
    },
    {
      countryName: "Mongolia",
      countryCode: "MN",
    },
    {
      countryName: "Montenegro",
      countryCode: "ME",
    },
    {
      countryName: "Montserrat",
      countryCode: "MS",
    },
    {
      countryName: "Morocco",
      countryCode: "MA",
    },
    {
      countryName: "Mozambique",
      countryCode: "MZ",
    },
    {
      countryName: "Myanmar",
      countryCode: "MM",
    },
    {
      countryName: "Namibia",
      countryCode: "NA",
    },
    {
      countryName: "Nauru",
      countryCode: "NR",
    },
    {
      countryName: "Nepal",
      countryCode: "NP",
    },
    {
      countryName: "Netherlands",
      countryCode: "NL",
    },
    {
      countryName: "New Caledonia",
      countryCode: "NC",
    },
    {
      countryName: "New Zealand",
      countryCode: "NZ",
    },
    {
      countryName: "Nicaragua",
      countryCode: "NI",
    },
    {
      countryName: "Niger",
      countryCode: "NE",
    },
    {
      countryName: "Nigeria",
      countryCode: "NG",
    },
    {
      countryName: "Niue",
      countryCode: "NU",
    },
    {
      countryName: "Norfolk Island",
      countryCode: "NF",
    },
    {
      countryName: "Northern Mariana Islands",
      countryCode: "MP",
    },
    {
      countryName: "Norway",
      countryCode: "NO",
    },
    {
      countryName: "Oman",
      countryCode: "OM",
    },
    {
      countryName: "Pakistan",
      countryCode: "PK",
    },
    {
      countryName: "Palau",
      countryCode: "PW",
    },
    {
      countryName: "Palestine, State of",
      countryCode: "PS",
    },
    {
      countryName: "Panama",
      countryCode: "PA",
    },
    {
      countryName: "Papua New Guinea",
      countryCode: "PG",
    },
    {
      countryName: "Paraguay",
      countryCode: "PY",
    },
    {
      countryName: "Peru",
      countryCode: "PE",
    },
    {
      countryName: "Philippines",
      countryCode: "PH",
    },
    {
      countryName: "Pitcairn",
      countryCode: "PN",
    },
    {
      countryName: "Poland",
      countryCode: "PL",
    },
    {
      countryName: "Portugal",
      countryCode: "PT",
    },
    {
      countryName: "Puerto Rico",
      countryCode: "PR",
    },
    {
      countryName: "Qatar",
      countryCode: "QA",
    },
    {
      countryName: "Republic of North Macedonia",
      countryCode: "MK",
    },
    {
      countryName: "Romania",
      countryCode: "RO",
    },
    {
      countryName: "Russian Federation",
      countryCode: "RU",
    },
    {
      countryName: "Rwanda",
      countryCode: "RW",
    },
    {
      countryName: "Réunion",
      countryCode: "RE",
    },
    {
      countryName: "Saint Barthélemy",
      countryCode: "BL",
    },
    {
      countryName: "Saint Helena, Ascension and Tristan da Cunha",
      countryCode: "SH",
    },
    {
      countryName: "Saint Kitts and Nevis",
      countryCode: "KN",
    },
    {
      countryName: "Saint Lucia",
      countryCode: "LC",
    },
    {
      countryName: "Saint Martin (French part)",
      countryCode: "MF",
    },
    {
      countryName: "Saint Pierre and Miquelon",
      countryCode: "PM",
    },
    {
      countryName: "Saint Vincent and the Grenadines",
      countryCode: "VC",
    },
    {
      countryName: "Samoa",
      countryCode: "WS",
    },
    {
      countryName: "San Marino",
      countryCode: "SM",
    },
    {
      countryName: "Sao Tome and Principe",
      countryCode: "ST",
    },
    {
      countryName: "Saudi Arabia",
      countryCode: "SA",
    },
    {
      countryName: "Senegal",
      countryCode: "SN",
    },
    {
      countryName: "Serbia",
      countryCode: "RS",
    },
    {
      countryName: "Seychelles",
      countryCode: "SC",
    },
    {
      countryName: "Sierra Leone",
      countryCode: "SL",
    },
    {
      countryName: "Singapore",
      countryCode: "SG",
    },
    {
      countryName: "Sint Maarten (Dutch part)",
      countryCode: "SX",
    },
    {
      countryName: "Slovakia",
      countryCode: "SK",
    },
    {
      countryName: "Slovenia",
      countryCode: "SI",
    },
    {
      countryName: "Solomon Islands",
      countryCode: "SB",
    },
    {
      countryName: "Somalia",
      countryCode: "SO",
    },
    {
      countryName: "South Africa",
      countryCode: "ZA",
    },
    {
      countryName: "South Georgia and the South Sandwich Islands",
      countryCode: "GS",
    },
    {
      countryName: "South Sudan",
      countryCode: "SS",
    },
    {
      countryName: "Spain",
      countryCode: "ES",
    },
    {
      countryName: "Sri Lanka",
      countryCode: "LK",
    },
    {
      countryName: "Sudan",
      countryCode: "SD",
    },
    {
      countryName: "Suriname",
      countryCode: "SR",
    },
    {
      countryName: "Svalbard and Jan Mayen",
      countryCode: "SJ",
    },
    {
      countryName: "Sweden",
      countryCode: "SE",
    },
    {
      countryName: "Switzerland",
      countryCode: "CH",
    },
    {
      countryName: "Syrian Arab Republic",
      countryCode: "SY",
    },
    {
      countryName: "Taiwan (Province of China)",
      countryCode: "TW",
    },
    {
      countryName: "Tajikistan",
      countryCode: "TJ",
    },
    {
      countryName: "Tanzania, United Republic of",
      countryCode: "TZ",
    },
    {
      countryName: "Thailand",
      countryCode: "TH",
    },
    {
      countryName: "Timor-Leste",
      countryCode: "TL",
    },
    {
      countryName: "Togo",
      countryCode: "TG",
    },
    {
      countryName: "Tokelau",
      countryCode: "TK",
    },
    {
      countryName: "Tonga",
      countryCode: "TO",
    },
    {
      countryName: "Trinidad and Tobago",
      countryCode: "TT",
    },
    {
      countryName: "Tunisia",
      countryCode: "TN",
    },
    {
      countryName: "Turkey",
      countryCode: "TR",
    },
    {
      countryName: "Turkmenistan",
      countryCode: "TM",
    },
    {
      countryName: "Turks and Caicos Islands",
      countryCode: "TC",
    },
    {
      countryName: "Tuvalu",
      countryCode: "TV",
    },
    {
      countryName: "Uganda",
      countryCode: "UG",
    },
    {
      countryName: "Ukraine",
      countryCode: "UA",
    },
    {
      countryName: "United Arab Emirates",
      countryCode: "AE",
    },
    {
      countryName: "United Kingdom of Great Britain and Northern Ireland",
      countryCode: "GB",
    },
    {
      countryName: "United States Minor Outlying Islands",
      countryCode: "UM",
    },
    {
      countryName: "United States of America",
      countryCode: "US",
    },
    {
      countryName: "Uruguay",
      countryCode: "UY",
    },
    {
      countryName: "Uzbekistan",
      countryCode: "UZ",
    },
    {
      countryName: "Vanuatu",
      countryCode: "VU",
    },
    {
      countryName: "Venezuela (Bolivarian Republic of)",
      countryCode: "VE",
    },
    {
      countryName: "Viet Nam",
      countryCode: "VN",
    },
    {
      countryName: "Virgin Islands (British)",
      countryCode: "VG",
    },
    {
      countryName: "Virgin Islands (U.S.)",
      countryCode: "VI",
    },
    {
      countryName: "Wallis and Futuna",
      countryCode: "WF",
    },
    {
      countryName: "Western Sahara",
      countryCode: "EH",
    },
    {
      countryName: "Yemen",
      countryCode: "YE",
    },
    {
      countryName: "Zambia",
      countryCode: "ZM",
    },
    {
      countryName: "Zimbabwe",
      countryCode: "ZW",
    },
    {
      countryName: "Åland Islands",
      countryCode: "AX",
    },
  ],
});
