import React from "react";
import { Text } from "@chakra-ui/react";

import colors from "../config/colors";
import { fontConfig as fonts } from "../config/fonts";
import { getPx, getSx } from "../utils/helpers";

const variants = {
  h1: { fontSize: fonts.size.h1, fontWeight: 600 },
  h15: { fontSize: fonts.size.h15, fontWeight: 600 },
  h2: { fontSize: fonts.size.h2, fontWeight: 600 },
  h3: { fontSize: fonts.size.primary, fontWeight: 600 },
  p: { fontSize: fonts.size.primary },
  lg: { fontSize: fonts.size.lg },
  lgb: { fontSize: fonts.size.lg, fontWeight: 600 },
  md: { fontSize: fonts.size.md },
  mdb: { fontSize: fonts.size.md, fontWeight: 600 },
  sm: { fontSize: fonts.size.sm },
  smb: { fontSize: fonts.size.sm, fontWeight: 600 },
};

export default function AppText({
  bold,
  clg,
  capitalize,
  center,
  children,
  lowercase,
  numberOfLines,
  right,
  strike,
  style,
  sx,
  underline,
  uppercase,
  variant = "p",
  ...otherProps
}) {
  if (clg) console.log("AppssText", children);
  return (
    <Text
      sx={getSx([
        {
          color: colors.black,
          fontSize: fonts.size.primary,
          fontWeight: 400,
        },
        {
          textDecorationLine: underline
            ? "underline"
            : strike
            ? "line-through"
            : underline && strike
            ? "underline line-through"
            : "none",
          textTransform: capitalize
            ? "capitalize"
            : lowercase
            ? "lowercase"
            : uppercase
            ? "uppercase"
            : "none",
          textAlign: right ? "right" : center ? "center" : "auto",
          bottom: 1.5, // if Quicksand font, uncomment this
        },
        variants[variant],
        bold ? { fontWeight: 700 } : {},
        style,
        sx,
      ])}
      noOfLines={numberOfLines}
      {...otherProps}
    >
      {children}
    </Text>
  );
}
