import { combineReducers } from "redux";
// slices
import userReducer from "./slices/user";
import appReducer from "./slices/app";

// ----------------------------------------------------------------------

const rootReducer = combineReducers({
  user: userReducer,
  app: appReducer,
});

export { rootReducer };

