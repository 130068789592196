import { useEffect, useState } from "react";
import {
  Flex,
  Center,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  VStack,
  HStack,
  Spacer,
} from "@chakra-ui/react";
import { Formik, Field } from "formik";
import { useYouwho } from "../package/react-youwho";
import AppText from "./AppText";
import SlidingPaneClaim from "./SlidingPaneClaim";
import { setAirdropsRewardsObjectRedux } from "../store/slices/app";
import { getPx } from "../utils/helpers";
import padding from "../config/padding";

export default function AppMain({}) {
  const { user, Parse, appStore, dispatch, Toast } = useYouwho();
  const { airdropsRewardsObject } = appStore;
  const [completedBy, setCompletedByArray] = useState([]);
  const [claimedBy, setClaimedByArray] = useState([]);
  const [claimableBy, setClaimableByArray] = useState([]);
  const [claimArray, setClaimArray] = useState([]);
  const [slidingPaneTitle, setSlidingPaneTitle] = useState(undefined);
  const [slidingPaneClaimState, setSlidingPaneClaimState] = useState(false);
  const [trackCode, setTrackCode] = useState(undefined);

  const handleSubmit = async ({ code }) => {
    try {
      setTrackCode(code);
      const results = await Parse.Cloud.run("getAirdropsRewardsArray", {
        code,
      });
      console.log("results: ", results);
      // const query = new Parse.Query(Parse.User);
      // query.equalTo("objectId", code);
      // const result = await query.first();

      if (Array.isArray(results) && results.length > 0) {
        let claimedByArrayCombined = [];
        let claimableByArrayCombined = [];
        let completedByArrayCombined = [];
        for (let i = 0; i < results.length; i++) {
          const result = results[i];
          const objCode = result.airdropsRewardsObj.get("code");
          const completed = result?.completedBy.map((x) => ({
            obj: x,
            code: objCode,
          }));
          completedByArrayCombined = completedByArrayCombined.concat(completed);

          const claimed = result?.claimedBy
            .filter((el) => {
              return !result?.completedBy.some((f) => {
                return f.attributes.username === el.attributes.username;
              });
            })
            .map((x) => ({ obj: x, code: objCode }));

          claimedByArrayCombined = claimedByArrayCombined.concat(claimed);

          const claimable = result?.claimableBy.map((x) => ({
            obj: x,
            code: objCode,
          }));
          claimableByArrayCombined = claimableByArrayCombined.concat(claimable);
        }
        setClaimedByArray(claimedByArrayCombined);
        setClaimableByArray(claimableByArrayCombined);
        setCompletedByArray(completedByArrayCombined);

        console.log("claimedByArrayCombined: ", claimedByArrayCombined);
        console.log("claimableByArrayCombined: ", claimableByArrayCombined);
        console.log("completedByArrayCombined: ", completedByArrayCombined);

        dispatch(setAirdropsRewardsObjectRedux(results[0]?.airdropsRewardsObj));
      } else {
        setTrackCode(undefined);
        setCompletedByArray([]);
        setClaimedByArray([]);
        setClaimableByArray([]);
        setClaimArray([]);
        dispatch(setAirdropsRewardsObjectRedux(undefined));
      }
      // console.log(airdropsRewardsObject?.id);
      // alert(airdropsRewardsObject?.id);
    } catch (error) {
      setTrackCode(undefined);
      setCompletedByArray([]);
      setClaimedByArray([]);
      setClaimableByArray([]);
      setClaimArray([]);
      dispatch(setAirdropsRewardsObjectRedux(undefined));
      Toast.show({
        type: "appAlert",
        // autoHide: false,
        // And I can pass any custom props I want
        // onHide: () => handleShowUnderlay(false),
        // onShow: () => handleShowUnderlay(true),
        props: {
          title: "Error",
          subtitle: "Could not get referral code stats. Try another code.",
          variant: "error",
        },
      });
    }
  };

  const handleOpenSlide = (code) => {
    if (code === "claimedBy") {
      setClaimArray(claimedBy);
      setSlidingPaneTitle("Claims in progress by");
      setSlidingPaneClaimState(true);
    } else if (code === "claimableBy") {
      setClaimArray(claimableBy);
      setSlidingPaneTitle("Claimable by");
      setSlidingPaneClaimState(true);
    } else if (code === "completedBy") {
      setClaimArray(completedBy);
      setSlidingPaneTitle("Claims completed by");
      setSlidingPaneClaimState(true);
    }
  };

  useEffect(() => {
    setCompletedByArray([]);
    setClaimedByArray([]);
    setClaimableByArray([]);
    setClaimArray([]);
    dispatch(setAirdropsRewardsObjectRedux(undefined));
  }, []);

  return (
    <Flex
      className="button-shadow"
      bg="white"
      // alignItems="center"
      justifyContent="center"
      minW={["100vw", "400px"]}
      maxW={["100vw"]}
      minH="300px"
      overflow="hidden"
      borderRadius={["0", "30px"]}
      px={["4", "7"]}
      py={["7", "7"]}
      top={["53%", , , "50%"]}
      left={["0", "50%"]}
      transform={["translate(0, -50%)", "translate(-50%, -50%)"]}
      pos="absolute"
      flex="1"
    >
      <VStack spacing={4} sx={{ width: "100%", alignItems: "flex-start" }}>
        {/* <AppText variant="h2">Hello {user.attributes?.username}</AppText> */}
        <AppText variant="h2">Referral code stats</AppText>
        <Formik
          initialValues={{
            code: "",
          }}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, errors, touched, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <FormControl isInvalid={!!errors.code && touched.code}>
                <Flex sx={{ flexDirection: "row" }}>
                  {/* <FormLabel htmlFor="text">Referral Code</FormLabel> */}
                  <Field
                    as={Input}
                    id="code"
                    name="code"
                    placeholder="referral code"
                    variant="outline"
                    focusBorderColor="green.400"
                    sx={{ marginRight: "20px" }}
                    validate={(value) => {
                      let error;

                      if (value.length < 5) {
                        error = "Code must contain at least 5 characters";
                      }

                      return error;
                    }}
                  />

                  <Button
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting}
                    type="submit"
                    colorScheme="green"
                    sx={{ paddingX: "40px" }}
                    // width="full"
                  >
                    Submit
                  </Button>
                </Flex>
                <FormErrorMessage>{errors.code}</FormErrorMessage>
              </FormControl>
            </form>
          )}
        </Formik>
        {airdropsRewardsObject ? (
          <Flex sx={{ flexDirection: "column", width: "100%" }}>
            <Divider sx={{ borderColor: "black.500" }} />
            <AppText variant="h3">
              {airdropsRewardsObject?.attributes?.code}
            </AppText>

            <AppText marginTop={getPx(padding.ms)} variant="lg">
              Reward details
            </AppText>
            <AppText variant="lgb">
              {airdropsRewardsObject?.attributes?.airdropDetails}
            </AppText>
            {Array.isArray(
              airdropsRewardsObject?.attributes?.referrerChildrenCodes
            ) && (
              <>
                <AppText marginTop={getPx(padding.ms)} variant="lg">
                  Your referrers codes
                </AppText>
                <AppText variant="lgb">
                  {airdropsRewardsObject?.attributes?.referrerChildrenCodes.join(
                    ", "
                  )}
                </AppText>
              </>
            )}
            <Divider sx={{ borderColor: "black.500" }} />
            <AppText variant="mdb" center>
              click on the numbers below to view usernames
            </AppText>
            {claimableBy?.length > 0 && (
              <Box
                onClick={() => handleOpenSlide("claimableBy")}
                cursor="pointer"
              >
                <Box h="10px" />
                <AppText variant="lg">Claimable by</AppText>
                <AppText variant="lgb">
                  {claimableBy?.length ?? 0} users
                </AppText>
              </Box>
            )}
            <Box
              onClick={() => handleOpenSlide("completedBy")}
              cursor="pointer"
            >
              <Box h="10px" />
              <AppText variant="lg">Claims completed by</AppText>
              <AppText variant="lgb">{completedBy?.length ?? 0} users</AppText>
            </Box>
            <Box onClick={() => handleOpenSlide("claimedBy")} cursor="pointer">
              <Box h="10px" />
              <AppText variant="lg">Claims in progress by</AppText>
              <AppText variant="lgb">{claimedBy?.length ?? 0} users</AppText>
            </Box>
          </Flex>
        ) : (
          <>
            <Flex sx={{ flexDirection: "column", width: "100%" }}>
              <Divider sx={{ borderColor: "black.500" }} />
              <AppText variant="lgb" center>
                Enter a referral code above to track stats
              </AppText>
            </Flex>
          </>
        )}
      </VStack>
      <SlidingPaneClaim
        slidingPaneClaimState={slidingPaneClaimState}
        setSlidingPaneClaimState={setSlidingPaneClaimState}
        slidingPaneTitle={slidingPaneTitle}
        claimArray={claimArray}
        trackCode={trackCode}
      />
    </Flex>
  );
}
