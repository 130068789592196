export default Object.freeze({
  // colors
  primary: "#00c999",
  primary50: "#00c99980",
  // secondary: "#ffb369",
  secondary: "#ff7e00",
  tertiary: "#1e90ff",
  // danger: "#ff5252",
  danger: "#ff0000",
  info: "#ff5e0e",
  customer: "#00c999",
  provider: "#ff5e0e",

  // black white
  black0: "#000000",
  black80: "#000000CC",
  black50: "#00000080",
  black20: "#0000001A",
  black: "#222222",
  white: "#ffffff",
  white50: "#ffffff80",
  white80: "#ffffffCC",

  // grays
  grayF7: "#f7f7f7",
  grayF4: "#f4f4f4",
  grayE: "#eeeeee",
  grayD: "#dddddd",
  gray9: "#999999",
  gray7: "#777777",
  gray6: "#666666",
  gray4: "#444444",
  gray0: "#0c0c0c",
  brand: {
    // colors
    primary: "#00c999",
    primary50: "#00c99980",
    secondary: "#ffb369",
    tertiary: "#1e90ff",
    danger: "#ff5252",
    info: "#ff5e0e",
    customer: "#00c999",
    provider: "#ff5e0e",

    // black white
    black0: "#000000",
    black80: "#000000CC",
    black50: "#00000080",
    black20: "#0000001A",
    black: "#222222",
    white: "#ffffff",
    white50: "#ffffff80",
    white80: "#ffffffCC",

    // grays
    grayF7: "#f7f7f7",
    grayF4: "#f4f4f4",
    grayE: "#eeeeee",
    grayD: "#dddddd",
    gray9: "#999999",
    gray7: "#777777",
    gray6: "#666666",
    gray4: "#444444",
    gray0: "#0c0c0c",
  },
  customerBrand: {
    50: "#e8fff9",
    100: "#b9ffee",
    200: "#8bffe3",
    300: "#5dffd8",
    400: "#2effcd",
    500: "#00c999",
    600: "#00a27b",
    700: "#007458",
    800: "#004635",
    900: "#004635",
  },
  providerBrand: {
    50: "#fff3e8",
    100: "#ffdcb9",
    200: "#ffc48b",
    300: "#ffad5d",
    400: "#ff952e",
    500: "#ff7e00",
    600: "#d16700",
    700: "#a25000",
    800: "#743900",
    900: "#462200",
  },
  infoBrand: {
    50: "#ffefe8",
    100: "#ffd1b9",
    200: "#ffb28b",
    300: "#ff935d",
    400: "#ff742e",
    500: "#ff5e0e",
    600: "#d14500",
    700: "#a23600",
    800: "#742600",
    900: "#461700",
  },
  errorBrand: {
    50: "#ffe8e8",
    100: "#ffb9b9",
    200: "#ff8b8b",
    300: "#ff5d5d",
    400: "#ff2e2e",
    500: "#ff0000",
    600: "#d10000",
    700: "#a20000",
    800: "#740000",
    900: "#460000",
  },
  grayBrand: {
    // grays
    50: "#ffffff",
    100: "#ffffff",
    200: "#ffffff",
    300: "#ffffff",
    400: "#ffffff",
    500: "#ffffff",
    600: "#f7f7f7",
    700: "#f4f4f4",
    800: "#eeeeee",
    900: "#dddddd",
  },
});

// Alpha Level	HEX Code
// 100%	FF
// 95%	F2
// 90%	E6
// 85%	D9
// 80%	CC
// 75%	BF
// 70%	B3
// 65%	A6
// 60%	99
// 55%	8C
// 50%	80
// 45%	73
// 40%	66
// 35%	59
// 30%	4D
// 25%	40
// 20%	33
// 15%	26
// 10%	1A
// 5%	0D
// 0%	00
