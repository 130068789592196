import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "react-sliding-pane/dist/react-sliding-pane.css";

import Parse from "parse";
// redux
import { store, persistor } from "./store/store";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
// youwho
import { YouwhoProvider } from "./package/react-youwho";
import Fonts from "./config/fonts";
import App from "./App";
import theme from "./js/theme";

const parseProjectId = String(process.env.REACT_APP_PROJECT_ID);
// const appName = String(process.env.REACT_APP_NAME);
const serverUrl = String(process.env.REACT_APP_SERVER_URL);
// const serverUrl = "http://192.168.56.1:1337/server";

console.log("parseProjectId, serverUrl", parseProjectId, serverUrl);

Parse.initialize(parseProjectId); // jsKey
Parse.serverURL = serverUrl;
// Parse.enableLocalDatastore();
// Parse.setAsyncStorage(AsyncStorage);
// Parse.User.enableUnsafeCurrentUser();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <PersistGate persistor={persistor}>
        <ChakraProvider theme={theme}>
          <CSSReset />
          <Fonts />
          <YouwhoProvider>
            <App />
          </YouwhoProvider>
        </ChakraProvider>
      </PersistGate>
    </ReduxProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
